import React, { useEffect,  useState } from 'react';
import axios from 'axios';

import Form from '../components/Profil/Form'
import Head from '../components/Profil/Head';
import Card from '../components/Profil/Card';
import { useParams } from 'react-router-dom';

const Profile = () => {
 
  const [data, setData] = useState({});
  
const {id} = useParams()

  useEffect(() => {
     async function Handledata() {      
         const  response = await axios.post('http://44.198.227.6:8080/ca/getsinglecustomer',{ id:id})
         console.log(response.data)
       setData(response.data.users)
     }
     Handledata()
  }, [id]); 

  
  return (
    <div>
     
             <Head data={data} />
          <div className='container-fluid p-lg-0'>
            <div className='row my-3 g-2'>
              <div className='col-md-8 m-0 order-2 order-md-1'>
              <Form data={data}/>
              </div>
              <div className='col-md-4 m-0 order-1 order-md-2'>
                <Card data={data} />
              </div>
            </div>
          </div>
        
    
    </div>
  );
};

export default Profile;
