import { useEffect, useState } from "react";
import axios from "axios";

import SimpleSlider from "../components/Slider";
import LineChart from "../components/Index/barChart";

const Index = () => {
  const [title,setTitle] =useState('')
  // water & Rental
  const [data, setData] = useState({
    tungakaan:{
      water: 1220,
      rent:0
    },
    bayaran:{
      water: 0,
      rent:0
    },
    cajJulai:{
      water: 0,
      rent:0
    },
    akaun:{
      water: 0,
      rent:0
    }
  });
  // chart
  
  const [chart, setChart] = useState({});

  const [rentalValues, setRentalValues] = useState({});
  const [waterValues, setWaterValues] = useState({});

  const [id, setID] = useState(0);

  useEffect(() => {
    async function fetchData() {
      try {

        // for Slider
        const response = await axios.post('http://44.198.227.6:8080/ca/cards');
        console.log(response.data.values)
        setData(response.data.values);
      
        //  for charts
        const response1 = await axios.post('http://44.198.227.6:8080/ca/dashchart');
        console.log(response1.data)
        setChart(response1.data);
       

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);

 
  
  return (
    <div className="container-fluid p-2 p-lg-0">
      <div className="mx-4 mx-lg-3">
        {data && 
          <SimpleSlider
          // water 
            tungakaanWater={data.tungakaan?.water  } 
            bayaraanWater={data.bayaran?.water } 
            cajJulaiWater={data.cajJulai?.water } 
            akaunWater={data.akaun?.water } 
            // rental
            tungakaanRental={data.tungakaan?.rental} 
            bayaraanRental={data.bayaran?.rental } 
            cajJulaiRental={data.cajJulai?.rental }
            akaunRental={data.akaun?.rental }
          />
        }
      </div>

      {/* Line charts */}
      <div className="row my-1 g-2">
        {/* Total Chart */}
        <div className="col-lg-6 mb-3 ">
          <div className="card p-0 shadow" style={{ maxWidth: '99%' }}>
            <div className="card-body p-2">
              <p className="ms-2 m-0 fw-bold">JUMLAH AKAUN</p>
              <div className="pe-1">
                {chart  &&<LineChart data={chart?.jumlah_akaun?.air || {} } data1={chart?.jumlah_akaun?.sewa || {} } />}
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6">
        <div className="card p-0 shadow" style={{ maxWidth: '99%' }}>
            <div className="card-body p-2">
              <p className="ms-2 m-0 fw-bold">JUMLAH CAJ</p>
              <div className="pe-1">
                {chart && <LineChart data={chart.jumlah_caj?.air|| {} } data1={chart.jumlah_caj?.sewa || {} } />}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Active and Non-Active Charts */}
      <div className="row  g-2">
        {/* Active Accounts Chart */}
        <div className="col-lg-6">
          <div className="card p-0 shadow" style={{ maxWidth: '99%' }}>
            <div className="card-body p-2">
              <p className="ms-2 m-0 fw-bold">JUMLAH TUNGGAKAN</p>
              <div className="pe-1">
                {/* adjust the Rental data */}
                {chart && <LineChart data={chart?.jumlah_tunggakan?.air || {} } data1={chart?.jumlah_tunggakan?.sewa || {} } />}
              </div>
            </div>
          </div>
        </div>

        {/* Non-Active Accounts Chart */}
        <div className="col-lg-6">
          <div className="card p-0 shadow" style={{ maxWidth: '99%' }}>
            <div className="card-body p-2">
              <p className="ms-2 m-0 fw-bold">JUMLAH BAYARAN</p>
              <div className="pe-1">
                {/* Adjust the Water data */}
                {chart && <LineChart data={chart?.jumlah_bayaran?.air || {} } data1={chart?.jumlah_bayaran?.sewa|| {} } />}

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
