import UploadRentalBayaran from "./UploadRentalBayaran";
import UploadRentalBills from "./UploadRentalBills";
import UploadRentalTunggakan from "./UploadRentalTunggakan";

const Uploader = () => {
    return (
        <div className="bg-white p-2 rounded">
            <h3 className="m-2">SEWA Files Upload</h3>
            {/* <div className="m-2">
                <UploadRentalBayaran/>
            </div> */}
            <div className="m-2">
                <UploadRentalBills/>
            </div>
            <div className="m-2">
                <UploadRentalTunggakan/>
            </div>
            
           
        </div>
      );
}
 
export default Uploader;