import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import * as XLSX from 'xlsx';

const WaterTable = ({ data,hide }) => {
  const [status, setStatus] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const users = data?.users || [];

  // Format the price above to MYR using the locale, style, and currency.
  let MYR = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'MYR',
  });


  console.log(users[0])
  const res = users.filter(user => user.status === status);

  // (checking name, email, or account number)
  const filteredUsers = res.filter(user =>
    (user.name?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
    (user.email?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
    (user.no_akaun_air?.toLowerCase() || '').includes(searchQuery.toLowerCase())
  );
  
  const rowsPerPage = 10;
  const totalPages = Math.ceil(filteredUsers.length / rowsPerPage);

  const currentData = filteredUsers.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

  const handleDownload = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredUsers.map(user => ({
      Name: user.name,
      Email: user.email,
      Telephone: user.no_telephone,
      no_akaun_air: user.no_akaun_air,
      no_harta: user.no_harta,
      kawasan_id: user.kawasan_id,
      Status: user.status,
      debt: user.debt,
      credit: user.credit,
      arrears: user.arrears
    })));

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Users");
    XLSX.writeFile(workbook, "KawasanUsersData.xlsx");
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div>
      <div className="card">
        <div className="card-body p-0">
          <div className='d-flex justify-content-between'>
            <h6>Akaun Bertunggakan Tinggi</h6>
            <div className='d-flex align-items-center'>
              <input
                type='search'
                className='mb-3 me-2 ps-2 py-1 rounded border-1'
                style={{outline:"none"}}
                placeholder='Search...'
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)} // Update searchQuery on input change
              />
              <button className='btn btn-success' onClick={() => setStatus(true)}>Aktif</button>
              <button className='btn btn-danger mx-2' onClick={() => setStatus(false)}>Tidak Aktif</button>
              <span className={hide==='true'?"d-none":""}>
              <button className='btn btn-primary' onClick={handleDownload}>Klik untuk Muat Turun Fail Excel</button>
              </span>
            </div>
          </div>
          <div className='card-text' style={{ maxWidth: "100%", maxHeight: "67vh", overflow: "auto" }}>
            <table className="table align-items-center mb-0">
              <thead>
                <tr>
                  <th className="text-uppercase text-xxs font-weight-bolder opacity-7" style={{ color: "rgb(94, 114, 227)" }}>
                  Nama Pelanggan<br /><span className='text-secondary'>Nombor Akaun</span>
                  </th>
                  <th className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2" style={{ color: "rgb(94, 114, 227)" }}>No. Unit</th>
                  <th className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2" style={{ color: "rgb(94, 114, 227)" }}>Kenalan</th>
                  <th className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2" style={{ color: "rgb(94, 114, 227)" }}>Status</th>
                  <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7" style={{ color: "rgb(94, 114, 227)" }}>Tunggakan</th>
                  <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7" style={{ color: "rgb(94, 114, 227)" }}>Bayarn</th>
                  <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7" style={{ color: "rgb(94, 114, 227)" }}>Caj</th>
                  <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7" style={{ color: "rgb(94, 114, 227)" }}>Tindakan</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user, index) => (
                  <tr key={index}>
                    <td>
                      <div className="d-flex py-1">
                        <div>
                          <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtuphMb4mq-EcVWhMVT8FCkv5dqZGgvn_QiA&s" className="avatar avatar-sm me-3" alt={user.name} />
                        </div>
                        <div className="d-flex flex-column justify-content-center">
                          <h6 className="mb-0 text-xs">{user.name}</h6>
                          <p className="text-xs text-secondary mb-0">{user.no_akaun_air}</p>
                        </div>
                      </div>
                    </td>
                    <td className="address-column" style={{ maxWidth: '150px', wordWrap: 'break-word', whiteSpace: 'normal' }}>
                      <p className="text-xs font-weight-bold mb-0">{user.unit_no}</p>
                      <p className="text-xs text-secondary mb-0">{user.no_harta}</p>
                    </td>
                    <td>
                      <p className="text-xs font-weight-bold mb-0">{user.email}</p>
                      <p className="text-xs text-secondary mb-0">{user.no_telephone}</p>
                    </td>
                    <td>
                      <span className={`badge badge-sm ${user.status ? 'badge-success' : 'badge-danger'}`}>
                        {user.status ? 'Active' : 'Not Active'}
                      </span>
                    </td>
                    <td className="align-middle text-center text-sm">
                      <span className="text-secondary text-xs font-weight-bold">RM {user?.water_bills_tunggakan[user?.water_bills_tunggakan.length - 1]?.remaining_amount || 0.00}</span>
                    </td>
                    <td className="align-middle text-center">
                      <span className="text-secondary text-xs font-weight-bold">RM {user?.water_bills_bayaran[user.water_bills_bayaran.length - 1]?.arrears || 0.00}</span>
                    </td>
                    <td className="align-middle text-center">
                      <span className="text-secondary text-xs font-weight-bold">RM {user.water_bills_charge[user.water_bills_charge.length - 1]?.amount || 0.00}</span>
                    </td>
                    <td className="align-middle text-center">
                      <span className="text-secondary text-xs font-weight-bold">
                        <Link to={`/profile/${user.id}`} className='text-primary'>View</Link>
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="d-flex justify-content-between p-3">
              <button onClick={goToPreviousPage} disabled={currentPage === 1} className="btn btn-secondary btn-sm">Previous</button>
              <span>Page {currentPage} of {totalPages}</span>
              <button onClick={goToNextPage} disabled={currentPage === totalPages} className="btn btn-secondary btn-sm">Next</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WaterTable;
