const Link = ({expandedItems,toggleItem,setSubMenu,setData,setTitle,setArea,barData,itemKey,linkName,setMainTitle,titleName}) => {
    const key = itemKey
      return ( 
  
          <li className="nav-item">
                      <p
                        className="fw-bold title mb-1 d-flex align-items-center justify-content-between"
                        style={{ color: 'gray', cursor: 'pointer' }}
                        onClick={() => toggleItem(key)}
                      >
                        <span className="fw-light" >
                          <i className=" me-2 fa-solid fa-file-invoice" style={{ color: "orange" }}></i>
                          <span style={{textTransform:"uppercase"}}>
                          {linkName}
                          </span>
                        </span>
                        <i
                          className={`fas ${expandedItems[key] ? 'fa-chevron-up' : 'fa-chevron-down'}`}
                          style={{ fontSize: '.7rem' }}
                        ></i>
                      </p>
                      {expandedItems[key] && (
                        <ul className="nav flex-column ms-3">
  
                          <li className="nav-item">
                            <p
                              className="fw-bold title mb-1 d-flex align-items-center justify-content-between"
                              style={{ color: 'gray', cursor: 'pointer' }}
                              onClick={() => {
                                setSubMenu(true)
                                setData(barData?.caj)
                                setTitle("Caj")
                                setArea(linkName)
                              }}
  
                            >
                              <span className="fw-light">
                                <i className=" me-2 fa-solid fa-file-invoice" style={{ color: "orange" }}></i>CAJ
                              </span>
                              <i
                                className={`fas fa-chevron-right`}
  
                                style={{ fontSize: '.7rem' }}
                              ></i>
                            </p>
  
                          </li>
  
                          <li className="nav-item">
                            <p
                              className="fw-bold title mb-1 d-flex align-items-center justify-content-between"
                              style={{ color: 'gray', cursor: 'pointer' }}
                              onClick={() => {
                                setSubMenu(true)
                                setData(barData?.bayaran)
                                setTitle("Bayaran")
                                setArea(linkName)

                              }}
                            >
                              <span className="fw-light">
                                <i className=" me-2 fa-solid fa-file-invoice" style={{ color: "orange" }}></i>BAYARAN
                              </span>
                              <i
                                className={`fas fa-chevron-right`}
                                style={{ fontSize: '.7rem' }}
                              ></i>
                            </p>
  
                          </li>
  
                          <li className="nav-item">
                            <p
                              className="fw-bold title mb-1 d-flex align-items-center justify-content-between"
                              style={{ color: 'gray', cursor: 'pointer' }}
                              onClick={() => {
                                setSubMenu(true)
                                setData(barData?.tunggakan)
                                setTitle("Tunggakan")
                                setArea(linkName)

                              }}
                            >
                              <span className="fw-light">
                                <i className=" me-2 fa-solid fa-file-invoice" style={{ color: "orange" }}></i>TUNGGAKAN
                              </span>
                              <i
                                className={`fas fa-chevron-right`}
  
                                style={{ fontSize: '.7rem' }}
                              ></i>
                            </p>
  
                          </li>
  
                          {/* <li className="nav-item">
                            <p
                              className="fw-bold title mb-1 d-flex align-items-center justify-content-between"
                              style={{ color: 'gray', cursor: 'pointer' }}
                              onClick={() => {
                                setSubMenu(true)
                                setData(barData?.lebihan)
                                setTitle("Lebihan")
                                setArea(linkName)
                              }}
                            >
                              <span className="fw-light">
                                <i className=" me-2 fa-solid fa-file-invoice" style={{ color: "orange" }}></i>LEBIHAN BAYARAN
                              </span>
                              <i
                                className={`fas fa-chevron-right`}
                                style={{ fontSize: '.7rem' }}
                              ></i>
                            </p>
                          
                          </li> */}
  
                          <li className="nav-item">
                            <p
                              className="fw-bold title mb-1 d-flex align-items-center justify-content-between"
                              style={{ color: 'gray', cursor: 'pointer' }}
                              onClick={() => {
                                setSubMenu(true)
                                setData(barData?.akaun)
                                setTitle("Akaun")
                                setArea(linkName)
                              }}
                            >
                              <span className="fw-light">
                                <i className=" me-2 fa-solid fa-file-invoice" style={{ color: "orange" }}></i>JUMLAH AKAUN
                              </span>
                              <i
                                className={`fas fa-chevron-right`}
                                style={{ fontSize: '.7rem' }}
                              ></i>
                            </p>
                           
                          </li>
                        </ul>
                      )}
                    </li>
  
       );
  }
   
  export default Link;