import { type } from "@testing-library/user-event/dist/type";
import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { NavLink, useParams } from "react-router-dom";
import * as XLSX from 'xlsx';


const SubSideBar = ({ setSubMenu, data, title, area, mainTitle,id }) => {
  const [excelData,setExcelData] = useState({users:[]})
  const [expandedItems, setExpandedItems] = useState({});
  const sidebarRef = useRef(null); // Create a ref for the sidebar

     const token = localStorage.getItem('token')

  useEffect(() => {
       async function getAllCustomersKawasan() {
            const response = await axios.post('http://44.198.227.6:8080/ca/getallkawasancustomers',
                 { id: id,
                  type:area,
                  token:token
                  }
            )
            // console.log( response.data)
            setExcelData(response.data)
       }
       getAllCustomersKawasan()

  }, [id])

  const users = excelData?.users || [];

  const handleDownload = () => {
    const worksheet = XLSX.utils.json_to_sheet(users.map(user => ({
      Name: user.name,
      Email: user.email,
      Telephone: user.no_telephone,
      no_akaun_air: user.no_akaun_air,
      no_harta: user.no_harta,
      kawasan_id: user.kawasan_id,
      Status: user.status,
      debt: user.debt,
      credit: user.credit,
      arrears: user.arrears
    })));

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Users");
    XLSX.writeFile(workbook, "KawasanUsersData.xlsx");
  };


  const toggleItem = (id) => {
    setExpandedItems((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };
  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setSubMenu(false); // Close the sidebar when clicking outside
    }
  };


  const zone1 = data ? data.filter(item => item.zone === 1) : [];
  const zone2 = data ? data.filter(item => item.zone === 2) : [];
  const zone3 = data ? data.filter(item => item.zone === 3) : [];
  const zone4 = data ? data.filter(item => item.zone === 4) : [];

  useEffect(() => {
    // Attach event listener to detect clicks outside
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Clean up the event listener on component unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div id="add" ref={sidebarRef}>
      <div
        className=" ms-2"
        style={{ maxHeight: "80vh", overflow: "auto" }}
      >
         {/* {`the ID is: ${id} && Area: ${area}`} */}
        <div className="border rounded d-flex justify-content-between px-2 pt-1 me-2 mt-2 bg-primary">
          <p className="text-white" style={{textTransform:"uppercase"}}>{area}: {title || "No Name"}</p>
          <span
            onClick={() => setSubMenu(false)}
            className=" "
            style={{ cursor: "pointer" }}
          >
            <i className="fa-solid fa-xmark text-white"></i>
          </span>
        </div>
        <ul className="nav nav-pills flex-column mynav px-2 mt-1">
          {/* Dashboard item */}

          {zone1 && zone1.length > 0 ? (
            <li className="nav-item">
              <p
                className="fw-bold title mb-1 d-flex align-items-center justify-content-between"
                style={{ color: "gray", cursor: "pointer" }}
                onClick={() => toggleItem("a1")}
              >
                <span className="fw-light">
                  <i className="fas fa-city me-2" style={{ color: "orange" }}></i>
                  ZONE 1
                </span>
                <i
                  className={`fas ${expandedItems["a1"] ? "fa-chevron-up" : "fa-chevron-down"
                    }`}
                  style={{ fontSize: ".7rem" }}
                ></i>
              </p>
              {expandedItems["a1"] && (
                <ul className="nav flex-column ms-3">
                  {zone1 && zone1.map((item, index) => (
                    <NavLink key={index} 
                      to={`/${mainTitle}/${area}/${title}/zone1/${item.kawasan_no}`}
                      state={{ title: title, area: area, kawasan_no: item.kawasan_no }}
                      className="ps-2 fw-light my-1 text-secondary text-sm nav-link"
                      onClick={mainTitle=== "report" ?handleDownload:""}
                    >
                      {item.name}
                    </NavLink>
                  ))}

                </ul>
              )}
            </li>) : null}


          {zone2 && zone2.length > 0 ? (
            <li className="nav-item">
              <p
                className="fw-bold title mb-1 d-flex align-items-center justify-content-between"
                style={{ color: "gray", cursor: "pointer" }}
                onClick={() => toggleItem("a2")}
              >
                <span className="fw-light">
                  <i className="fas fa-city me-2" style={{ color: "orange" }}></i>
                  ZONE 2
                </span>
                <i
                  className={`fas ${expandedItems["a2"] ? "fa-chevron-up" : "fa-chevron-down"
                    }`}
                  style={{ fontSize: ".7rem" }}
                ></i>
              </p>
              {expandedItems["a2"] && (
                <ul className="nav flex-column ms-3">
                  {zone2 && zone2.map((item, index) => (
                    <NavLink key={index}
                      to={`/${mainTitle}/${area}/${title}/zone2/${item.kawasan_no}`}
                      state={{ title: title, area: area, kawasan_no: item.kawasan_no }}
                      className="ps-2 fw-light my-1 text-secondary text-sm nav-link"
                      onClick={mainTitle=== "report" ?handleDownload:""}
                    >
                      {item.name}
                    </NavLink>
                  ))}
                </ul>
              )}
            </li>
          ) : null}

          {zone3 && zone3.length > 0 ? (
            <li className="nav-item">
              <p
                className="fw-bold title mb-1 d-flex align-items-center justify-content-between"
                style={{ color: "gray", cursor: "pointer" }}
                onClick={() => toggleItem("a3")}
              >
                <span className="fw-light">
                  <i className="fas fa-city me-2" style={{ color: "orange" }}></i>
                  ZONE 3
                </span>
                <i
                  className={`fas ${expandedItems["a3"] ? "fa-chevron-up" : "fa-chevron-down"
                    }`}
                  style={{ fontSize: ".7rem" }}
                ></i>
              </p>
              {expandedItems["a3"] && (
                <ul className="nav flex-column ms-3">
                  {zone3 && zone3.map((item, index) => (
                    <NavLink key={index}
                      to={`/${mainTitle}/${area}/${title}/zone3/${item.kawasan_no}`}
                      state={{ title: title, area: area, kawasan_no: item.kawasan_no }}
                      className="ps-2 fw-light my-1 text-secondary text-sm nav-link"
                      onClick={mainTitle=== "report" ?handleDownload:""}
                    >
                      {item?.name}
                    </NavLink>
                  ))}
                </ul>
              )}
            </li>
          ) : null}

          {zone4 && zone4.length > 0 ? (
            <li className="nav-item">
              <p
                className="fw-bold title mb-1 d-flex align-items-center justify-content-between"
                style={{ color: "gray", cursor: "pointer" }}
                onClick={() => toggleItem("a4")}
              >
                <span className="fw-light">
                  <i className="fas fa-city me-2" style={{ color: "orange" }}></i>
                  ZONE 4
                </span>
                <i
                  className={`fas ${expandedItems["a4"] ? "fa-chevron-up" : "fa-chevron-down"
                    }`}
                  style={{ fontSize: ".7rem" }}
                ></i>
              </p>
              {expandedItems["a4"] && (
                <ul className="nav flex-column ms-3">
                  {zone4 && zone4.map((item, index) => (
                    <NavLink key={index}
                      to={`/${mainTitle}/${area}/${title}/zone4/${item.kawasan_no}`}
                      state={{ title: title, area: area, kawasan_no: item.kawasan_no }}
                      className="ps-2 fw-light my-1 text-secondary text-sm nav-link"
                      onClick={mainTitle=== "report" ?handleDownload:""}
                    >
                      {item?.name}
                    </NavLink>
                  ))}
                </ul>
              )}
            </li>
          ) : null}
        </ul>
      </div>
    </div>
  );
};

export default SubSideBar;
