import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Card from "../components/Rental/Card";
import RentalTable from "../components/Rental/Table";
import { type } from "@testing-library/user-event/dist/type";

const Rental = ({hide, setId }) => {


     const [data, setData] = useState({ card:[],users: [] });
     const token = localStorage.getItem('token')

     const { id } = useParams();

     useEffect(() => {
          async function getAllCustomersKawasan() {
            const response = await axios.post(
              "http://44.198.227.6:8080/ca/getallkawasancustomers",
              {
                id: id,
                type: "Air",
                token: token,
              }
            );
            console.log(response.data);
            setData(response.data);
          }
         
           
          getAllCustomersKawasan();

       
        
        }, [id,setId]);

        useEffect(() => {
          if (typeof setId === "function") {
              setId(id);
          }
      }, [id, setId]);
    

     return (
          <div className="">
               <div className={`row g-3 my-2 ${hide==='true'?"d-none":""}`}>
                    <div className='col-md-6 col-lg-3'> {/* Ensure correct column classes */}
                         {data && <Card title="Arrears" value={data.total_arrears} icon="fas fa-user" color=" rgb(94, 114, 227)" />}
                    </div>

                    <div className='col-md-6 col-lg-3'> {/* Ensure correct column classes */}
                         {data && <Card title="Debt" value={data.total_debt} icon="fas fa-user" color=" rgb(210, 117, 233)" />}
                    </div>

                    <div className='col-md-6 col-lg-3'> {/* Ensure correct column classes */}
                         {data && <Card title="Credit" value={data.total_credit} icon="fas fa-user" color="orange" />}
                    </div>

                    <div className='col-md-6 col-lg-3'> {/* Ensure correct column classes */}
                         {data && <Card title="Total Customers" value={data.count} icon="fas fa-user" color=" rgb(129, 27, 197)" />}
                    </div>
               </div>
               <RentalTable data={data} hide={hide}/>
          </div>
     );
}

export default Rental;