import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Card from "../components/water/Card";
import WaterTable from "../components/water/Table";

const Water = ({hide,setId }) => {


     const [data, setData] = useState({ users: [] });
     const { id } = useParams();
     const token = localStorage.getItem('token')
     console.log(id)
     useEffect(() => {
          async function getAllCustomersKawasan() {
            const response = await axios.post(
              "http://44.198.227.6:8080/ca/getallwatercustomerssinglekawasan",
              {
               kawasan_id: id,
                type: "Air",
                token: token,
              }
            );
            console.log(response.data);
            setData(response.data);
          }
      
      
          getAllCustomersKawasan();
  
        }, [id,setId]);
        
        useEffect(() => {
          if (typeof setId === "function") {
              setId(id);
          }
      }, [id, setId]);
    

     return (
          <div className="p-2">
               <div className={`row g-3 my-2 ${hide==='true'?"d-none":""}`}>
                    <div className='col-md-6 col-lg-3'> {/* Ensure correct column classes */}
                         {data && <Card title="Jumlah Amaun" value={data.total_arrears} icon="fas fa-user" color=" rgb(94, 114, 227)" />}
                    </div>

                    <div className='col-md-6 col-lg-3'> {/* Ensure correct column classes */}
                         {data && <Card title="Jumlah Caj" value={data.total_debt} icon="fas fa-user" color=" rgb(210, 117, 233)" />}
                    </div>

                    <div className='col-md-6 col-lg-3'> {/* Ensure correct column classes */}
                         {data && <Card title="Jumlah Bayaran" value={data.total_credit} icon="fas fa-user" color="orange" />}
                    </div>

                    <div className='col-md-6 col-lg-3'> {/* Ensure correct column classes */}
                         {data && <Card title="Jumlah Pelanggan" value={data.count} icon="fas fa-user" color=" rgb(129, 27, 197)" />}
                    </div>
               </div>
               <WaterTable data={data} />
          </div>
     );
}

export default Water;