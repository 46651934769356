
// all style
import './assets/css/argon-dashboard.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './App.css';

// React-Routers-links
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// components
import SideBar from './components/Sidebar/SideBar'
import Navbar from './components/Navbar';
// layouts
import Login from './Layouts/Login';

import Profile from './Layouts/Profile';
import Uploader from './components/uploader/rental/Uploader';
import WaterUploader from './components/uploader/water/WaterUploader';
import Water from './Layouts/Water';
import Rental from './Layouts/Rental';

import Zone from './Layouts/Zone';
import Index from './Layouts/Index';
import { useState } from 'react';
import Jual from './Layouts/Jual';
import Kosong from './Layouts/Kosong';
import Rampas from './Layouts/Rampas';


function App() {
  const [id,setId] = useState(null)

  const handleSetId = (id) => {
    console.log("ID received from Rental component:", id);
  };

  const token = localStorage.getItem('token')

  if (!token) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Login />} />
        </Routes>
      </BrowserRouter>
    )
  } else {
    return (
      <BrowserRouter>
        <div className="App" style={{ minHeight: '100vh', width: '100%' }}>
          <div className="container-fluid p-0 d-flex h-100" style={{ height: '100vh' }}>

            <SideBar  id={id}/>

            <div className="bg flex-fill" style={{maxHeight:"99vh",overflow:"auto"}}>
              <Navbar />
              <div className="py-3 px-lg-3">
                <Routes>

                  <Route path='/' element={<Index />} />
                  
                  {/* uploader */}
                  <Route path='/uploaderAir' element={<WaterUploader/>}/>
                  <Route path='/uploaderSewa' element={<Uploader/>}/>
                  {/* status */}
                  <Route path='/status/rampas/:id' element={<Rental/>}/>
                  <Route path='/status/sewa/:id' element={<Rental/>}/>
                  <Route path='/status/beli/:id' element={<Rental/>}/>

                  {/* <Route path='/zone/:id' element={<Zone />} /> */}
                  
                  <Route path='/profile/:id' element={<Profile />} />
              
                    {/*Rental Caj*/}
                  <Route path={`/utama/Air/Caj/zone1/:id`} element={<Zone/>}/>
                  <Route path={`/utama/Air/Caj/zone2/:id`} element={<Zone/>}/>
                  <Route path={`/utama/Air/Caj/zone3/:id`} element={<Zone/>}/>
                  <Route path={`/utama/Air/Caj/zone4/:id`} element={<Zone/>}/>
                  {/*Rental Bayaran*/}
                  <Route path={`/utama/Air/Bayaran/zone1/:id`} element={<Zone/>}/>
                  <Route path={`/utama/Air/Bayaran/zone2/:id`} element={<Zone/>}/>
                  <Route path={`/utama/Air/Bayaran/zone3/:id`} element={<Zone/>}/>
                  <Route path={`/utama/Air/Bayaran/zone4/:id`} element={<Zone/>}/>
                   {/*Rental Tunggakan*/}
                  <Route path={`/utama/Air/Tunggakan/zone1/:id`} element={<Zone/>}/>
                  <Route path={`/utama/Air/Tunggakan/zone2/:id`}  element={<Zone/>}/>
                  <Route path={`/utama/Air/Tunggakan/zone3/:id`} element={<Zone/>}/>
                  <Route path={`/utama/Air/Tunggakan/zone4/:id`}  element={<Zone/>}/>
                  {/*Rental Lebihan*/}
                  <Route path={`/utama/Air/Lebihan/zone1/:id`}  element={<Zone/>}/>
                  <Route path={`/utama/Air/Lebihan/zone2/:id`} element={<Zone/>}/>
                  <Route path={`/utama/Air/Lebihan/zone3/:id`} element={<Zone/>}/>
                  <Route path={`/utama/Air/Lebihan/zone4/:id`} element={<Zone/>}/>
                   {/*Rental Akaun*/}
                  <Route path={`/utama/Air/Akaun/zone1/:id`} element={<Zone/>}/>
                  <Route path={`/utama/Air/Akaun/zone2/:id`} element={<Zone/>}/>
                  <Route path={`/utama/Air/Akaun/zone3/:id`} element={<Zone/>}/>
                  <Route path={`/utama/Air/Akaun/zone4/:id`} element={<Zone/>}/>

                  
                   {/*Water Caj*/}                  
                  <Route path={`/utama/Sewa/Caj/zone2/:id`} element={<Zone/>}/>
                  <Route path={`/utama/Sewa/Caj/zone4/:id`} element={<Zone/>}/>             
                  {/*Water Bayaran*/}                
                  <Route path={`/utama/Sewa/Bayaran/zone2/:id`} element={<Zone/>}/>
                  <Route path={`/utama/Sewa/Bayaran/zone4/:id`} element={<Zone/>}/>              
                   {/*Water Tunggakan*/}              
                  <Route path={`/utama/Sewa/Tunggakan/zone2/:id`}  element={<Zone/>}/>
                  <Route path={`/utama/Sewa/Tunggakan/zone4/:id`} element={<Zone/>}/>            
                  {/*Water Lebihan*/}
                  <Route path={`/utama/Sewa/Lebihan/zone2/:id`} element={<Zone/>}/>
                  <Route path={`/utama/Sewa/Lebihan/zone4/:id`} element={<Zone/>}/>                 
                   {/*Water Akaun*/}
                  <Route path={`/utama/Sewa/Akaun/zone2/:id`} element={<Zone/>}/>
                  <Route path={`/utama/Sewa/Akaun/zone4/:id`} element={<Zone/>}/>

                  {/* customers */}
                   {/*Rental Caj*/}
                  <Route path={`/customers/Air/Caj/zone1/:id`} element={<Water/>}/>
                  <Route path={`/customers/Air/Caj/zone2/:id`} element={<Water/>}/>
                  <Route path={`/customers/Air/Caj/zone3/:id`} element={<Water/>}/>
                  <Route path={`/customers/Air/Caj/zone4/:id`} element={<Water/>}/>
                  {/*Water Bayaran*/}
                  <Route path={`/customers/Air/Bayaran/zone1/:id`} element={<Water/>}/>
                  <Route path={`/customers/Air/Bayaran/zone2/:id`} element={<Water/>}/>
                  <Route path={`/customers/Air/Bayaran/zone3/:id`} element={<Water/>}/>
                  <Route path={`/customers/Air/Bayaran/zone4/:id`} element={<Water/>}/>
                   {/*Water Tunggakan*/}
                  <Route path={`/customers/Air/Tunggakan/zone1/:id`} element={<Water/>}/>
                  <Route path={`/customers/Air/Tunggakan/zone2/:id`}  element={<Water/>}/>
                  <Route path={`/customers/Air/Tunggakan/zone3/:id`} element={<Water/>}/>
                  <Route path={`/customers/Air/Tunggakan/zone4/:id`}  element={<Water/>}/>
                  {/*Water Lebihan*/}
                  <Route path={`/customers/Air/Lebihan/zone1/:id`}  element={<Water/>}/>
                  <Route path={`/customers/Air/Lebihan/zone2/:id`} element={<Water/>}/>
                  <Route path={`customers/Air/Lebihan/zone3/:id`} element={<Water/>}/>
                  <Route path={`/customers/Air/Lebihan/zone4/:id`} element={<Water/>}/>
                   {/*Water Akaun*/}
                  <Route path={`/customers/Air/Akaun/zone1/:id`} element={<Water/>}/>
                  <Route path={`/customers/Air/Akaun/zone2/:id`} element={<Water/>}/>
                  <Route path={`/customers/Air/Akaun/zone3/:id`} element={<Water/>}/>
                  <Route path={`/customers/Air/Akaun/zone4/:id`} element={<Water/>}/>

                  
                   {/*Water Caj*/}                  
                  <Route path={`/customers/Sewa/Caj/zone2/:id`} element={<Rental/>}/>
                  <Route path={`/customers/Sewa/Caj/zone4/:id`} element={<Rental/>}/>             
                  {/*Rental Bayaran*/}                
                  <Route path={`/customers/Sewa/Bayaran/zone2/:id`} element={<Rental/>}/>
                  <Route path={`/customers/Sewa/Bayaran/zone4/:id`} element={<Rental/>}/>              
                   {/*Rental Tunggakan*/}              
                  <Route path={`/customers/Sewa/Tunggakan/zone2/:id`}  element={<Rental/>}/>
                  <Route path={`/customers/Sewa/Tunggakan/zone4/:id`} element={<Rental/>}/>            
                  {/*Rental Lebihan*/}
                  <Route path={`/customers/Sewa/Lebihan/zone2/:id`} element={<Rental/>}/>
                  <Route path={`/customers/Sewa/Lebihan/zone4/:id`} element={<Rental/>}/>                 
                   {/*Rental Akaun*/}
                  <Route path={`customers/Sewa/Akaun/zone2/:id`} element={<Rental/>}/>
                  <Route path={`customers/Sewa/Akaun/zone4/:id`} element={<Rental/>}/>
               

                 {/* Status */}
                   {/*Jual Caj*/}
                   <Route path={`/status/Jual/Caj/zone1/:id`} element={<Jual/>}/>
                  <Route path={`/status/Jual/Caj/zone2/:id`} element={<Jual/>}/>
                  <Route path={`/status/Jual/Caj/zone3/:id`} element={<Jual/>}/>
                  <Route path={`/status/Jual/Caj/zone4/:id`} element={<Jual/>}/>
                  {/*Jual Bayaran*/}
                  <Route path={`/status/Jual/Bayaran/zone1/:id`} element={<Jual/>}/>
                  <Route path={`/status/Jual/Bayaran/zone2/:id`} element={<Jual/>}/>
                  <Route path={`/status/Jual/Bayaran/zone3/:id`} element={<Jual/>}/>
                  <Route path={`/status/Jual/Bayaran/zone4/:id`} element={<Jual/>}/>
                   {/*Jual Tunggakan*/}
                  <Route path={`/status/Jual/Tunggakan/zone1/:id`} element={<Jual/>}/>
                  <Route path={`/status/Jual/Tunggakan/zone2/:id`}  element={<Jual/>}/>
                  <Route path={`/status/Jual/Tunggakan/zone3/:id`} element={<Jual/>}/>
                  <Route path={`/status/Jual/Tunggakan/zone4/:id`}  element={<Jual/>}/>
                  {/*Jual Lebihan*/}
                  <Route path={`/status/Jual/Lebihan/zone1/:id`}  element={<Jual/>}/>
                  <Route path={`/status/Jual/Lebihan/zone2/:id`} element={<Jual/>}/>
                  <Route path={`status/Jual/Lebihan/zone3/:id`} element={<Jual/>}/>
                  <Route path={`/status/Jual/Lebihan/zone4/:id`} element={<Jual/>}/>
                   {/*Jual Akaun*/}
                  <Route path={`/status/Jual/Akaun/zone1/:id`} element={<Jual/>}/>
                  <Route path={`/status/Jual/Akaun/zone2/:id`} element={<Jual/>}/>
                  <Route path={`/status/Jual/Akaun/zone3/:id`} element={<Jual/>}/>
                  <Route path={`/status/Jual/Akaun/zone4/:id`} element={<Jual/>}/>


                   {/*Kosong Caj*/}
                  <Route path={`/status/kosong/Caj/zone1/:id`} element={<Kosong/>}/>
                  <Route path={`/status/kosong/Caj/zone2/:id`} element={<Kosong/>}/>
                  <Route path={`/status/kosong/Caj/zone3/:id`} element={<Kosong/>}/>
                  <Route path={`/status/kosong/Caj/zone4/:id`} element={<Kosong/>}/>
                  {/*kosong Bayaran*/}
                  <Route path={`/status/kosong/Bayaran/zone1/:id`} element={<Kosong/>}/>
                  <Route path={`/status/kosong/Bayaran/zone2/:id`} element={<Kosong/>}/>
                  <Route path={`/status/kosong/Bayaran/zone3/:id`} element={<Kosong/>}/>
                  <Route path={`/status/kosong/Bayaran/zone4/:id`} element={<Kosong/>}/>
                   {/*kosong Tunggakan*/}
                  <Route path={`/status/kosong/Tunggakan/zone1/:id`} element={<Kosong/>}/>
                  <Route path={`/status/kosong/Tunggakan/zone2/:id`}  element={<Kosong/>}/>
                  <Route path={`/status/kosong/Tunggakan/zone3/:id`} element={<Kosong/>}/>
                  <Route path={`/status/kosong/Tunggakan/zone4/:id`}  element={<Kosong/>}/>
                  {/*kosong Lebihan*/}
                  <Route path={`/status/kosong/Lebihan/zone1/:id`}  element={<Kosong/>}/>
                  <Route path={`/status/kosong/Lebihan/zone2/:id`} element={<Kosong/>}/>
                  <Route path={`status/kosong/Lebihan/zone3/:id`} element={<Kosong/>}/>
                  <Route path={`/status/kosong/Lebihan/zone4/:id`} element={<Kosong/>}/>
                   {/*kosong Akaun*/}
                  <Route path={`/status/kosong/Akaun/zone1/:id`} element={<Kosong/>}/>
                  <Route path={`/status/kosong/Akaun/zone2/:id`} element={<Kosong/>}/>
                  <Route path={`/status/kosong/Akaun/zone3/:id`} element={<Kosong/>}/>
                  <Route path={`/status/kosong/Akaun/zone4/:id`} element={<Kosong/>}/>


                   {/*Rampas Caj*/}
                   <Route path={`/status/rampas/Caj/zone1/:id`} element={<Rampas/>}/>
                  <Route path={`/status/rampas/Caj/zone2/:id`} element={<Rampas/>}/>
                  <Route path={`/status/rampas/Caj/zone3/:id`} element={<Rampas/>}/>
                  <Route path={`/status/rampas/Caj/zone4/:id`} element={<Rampas/>}/>
                  {/*rampas Bayaran*/}
                  <Route path={`/status/rampas/Bayaran/zone1/:id`} element={<Rampas/>}/>
                  <Route path={`/status/rampas/Bayaran/zone2/:id`} element={<Rampas/>}/>
                  <Route path={`/status/rampas/Bayaran/zone3/:id`} element={<Rampas/>}/>
                  <Route path={`/status/rampas/Bayaran/zone4/:id`} element={<Rampas/>}/>
                   {/*rampas Tunggakan*/}
                  <Route path={`/status/rampas/Tunggakan/zone1/:id`} element={<Rampas/>}/>
                  <Route path={`/status/rampas/Tunggakan/zone2/:id`}  element={<Rampas/>}/>
                  <Route path={`/status/rampas/Tunggakan/zone3/:id`} element={<Rampas/>}/>
                  <Route path={`/status/rampas/Tunggakan/zone4/:id`}  element={<Rampas/>}/>
                  {/*rampas Lebihan*/}
                  <Route path={`/status/rampas/Lebihan/zone1/:id`}  element={<Rampas/>}/>
                  <Route path={`/status/rampas/Lebihan/zone2/:id`} element={<Rampas/>}/>
                  <Route path={`status/rampas/Lebihan/zone3/:id`} element={<Rampas/>}/>
                  <Route path={`/status/rampas/Lebihan/zone4/:id`} element={<Rampas/>}/>
                   {/*rampas Akaun*/}
                  <Route path={`/status/rampas/Akaun/zone1/:id`} element={<Rampas/>}/>
                  <Route path={`/status/rampas/Akaun/zone2/:id`} element={<Rampas/>}/>
                  <Route path={`/status/rampas/Akaun/zone3/:id`} element={<Rampas/>}/>
                  <Route path={`/status/rampas/Akaun/zone4/:id`} element={<Rampas/>}/>


                   {/*Rental Caj*/}                  
                  <Route path={`/status/Sewa/Caj/zone2/:id`} element={<Rental/>}/>
                  <Route path={`/status/Sewa/Caj/zone4/:id`} element={<Rental/>}/>             
                  {/*Rental Bayaran*/}                
                  <Route path={`/status/Sewa/Bayaran/zone2/:id`} element={<Rental/>}/>
                  <Route path={`/status/Sewa/Bayaran/zone4/:id`} element={<Rental/>}/>              
                   {/*Rental Tunggakan*/}              
                  <Route path={`/status/Sewa/Tunggakan/zone2/:id`}  element={<Rental/>}/>
                  <Route path={`/status/Sewa/Tunggakan/zone4/:id`} element={<Rental/>}/>            
                  {/*Rental Lebihan*/}
                  <Route path={`/status/Sewa/Lebihan/zone2/:id`} element={<Rental/>}/>
                  <Route path={`/status/Sewa/Lebihan/zone4/:id`} element={<Rental/>}/>                 
                   {/*Rental Akaun*/}
                  <Route path={`status/Sewa/Akaun/zone2/:id`} element={<Rental/>}/>
                  <Route path={`status/Sewa/Akaun/zone4/:id`} element={<Rental/>}/>
               
               
                  {/* Report */}
                   {/*Rental Caj*/}
                   <Route path={`/report/Air/Caj/zone1/:id`} element={<Water hide='true' setId={setId}/>}/>
                  <Route path={`/report/Air/Caj/zone2/:id`} element={<Water hide='true' setId={setId}/>}/>
                  <Route path={`/report/Air/Caj/zone3/:id`} element={<Water hide='true' setId={setId}/>}/>
                  <Route path={`/report/Air/Caj/zone4/:id`} element={<Water hide='true' setId={setId}/>}/>
                  {/*Water Bayaran*/}
                  <Route path={`/report/Air/Bayaran/zone1/:id`} element={<Water hide='true' setId={setId}/>}/>
                  <Route path={`/report/Air/Bayaran/zone2/:id`} element={<Water hide='true' setId={setId}/>}/>
                  <Route path={`/report/Air/Bayaran/zone3/:id`} element={<Water hide='true' setId={setId}/>}/>
                  <Route path={`/report/Air/Bayaran/zone4/:id`} element={<Water hide='true' setId={setId}/>}/>
                   {/*Water Tunggakan*/}
                  <Route path={`/report/Air/Tunggakan/zone1/:id`} element={<Water hide='true' setId={setId}/>}/>
                  <Route path={`/report/Air/Tunggakan/zone2/:id`}  element={<Water hide='true' setId={setId}/>}/>
                  <Route path={`/report/Air/Tunggakan/zone3/:id`} element={<Water hide='true' setId={setId}/>}/>
                  <Route path={`/report/Air/Tunggakan/zone4/:id`}  element={<Water hide='true' setId={setId}/>}/>
                  {/*Water Lebihan*/}
                  <Route path={`/report/Air/Lebihan/zone1/:id`}  element={<Water hide='true' setId={setId}/>}/>
                  <Route path={`/report/Air/Lebihan/zone2/:id`} element={<Water hide='true' setId={setId}/>}/>
                  <Route path={`/report/Air/Lebihan/zone3/:id`} element={<Water hide='true' setId={setId}/>}/>
                  <Route path={`/report/Air/Lebihan/zone4/:id`} element={<Water hide='true' setId={setId}/>}/>
                   {/*Water Akaun*/}
                  <Route path={`/report/Air/Akaun/zone1/:id`} element={<Water hide='true' setId={setId}/>}/>
                  <Route path={`/report/Air/Akaun/zone2/:id`} element={<Water hide='true' setId={setId}/>}/>
                  <Route path={`/report/Air/Akaun/zone3/:id`} element={<Water hide='true' setId={setId}/>}/>
                  <Route path={`/report/Air/Akaun/zone4/:id`} element={<Water hide='true' setId={setId}/>}/>

                  
                   {/*Water Caj*/}                  
                  <Route path={`/report/Sewa/Caj/zone2/:id`} element={<Rental  hide="true" setId={setId} />}/>
                  <Route path={`/report/Sewa/Caj/zone4/:id`} element={<Rental  hide="true" setId={setId}/>}/>             
                  {/*Rental Bayaran*/}                
                  <Route path={`/report/Sewa/Bayaran/zone2/:id`} element={<Rental  hide="true" setId={setId}/>}/>
                  <Route path={`/report/Sewa/Bayaran/zone4/:id`} element={<Rental  hide="true" setId={setId}/>}/>              
                   {/*Rental Tunggakan*/}              
                  <Route path={`/report/Sewa/Tunggakan/zone2/:id`}  element={<Rental  hide="true" setId={setId}/>}/>
                  <Route path={`/report/Sewa/Tunggakan/zone4/:id`} element={<Rental  hide="true" setId={setId}/>}/>            
                  {/*Rental Lebihan*/}
                  <Route path={`/report/Sewa/Lebihan/zone2/:id`} element={<Rental  hide="true" setId={setId}/>}/>
                  <Route path={`/report/Sewa/Lebihan/zone4/:id`} element={<Rental  hide="true" setId={setId}/>}/>                 
                   {/*Rental Akaun*/}
                  <Route path={`/report/Sewa/Akaun/zone2/:id`} element={<Rental  hide="true" setId={setId}/>}/>
                  <Route path={`/report/Sewa/Akaun/zone4/:id`} element={<Rental  hide="true" setId={setId}/>}/>

                </Routes>
              </div>
            </div>
          </div>
        </div>
      </BrowserRouter>
    )
  }

}

export default App;